import { JSX } from "solid-js";
import c from "class-c";

import appIcon from "./assets/appIcon.svg";

import styles from "./Logo.module.scss";

declare namespace Logo {
  type Props = {
    class?: string;
    icon?: boolean;
    word?: boolean;
    children?: JSX.Element;
  };
}

function Logo({ icon, word, class: className, children }: D<Logo.Props>) {
  return (
    <div class={c`${styles["logo"]} ${className}`}>
      {icon && <img src={appIcon} />}
      {word && (
        <svg viewBox="0 0 96 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.85617 0.922363H0V29.4749H4.85617V0.922363Z"
            fill="currentColor"
          />
          <path
            d="M36.7156 0.922363H31.9023V29.4749H36.7156V0.922363Z"
            fill="currentColor"
          />
          <path
            d="M23.373 2.83506C23.373 1.28833 24.7043 0.00013746 26.3362 0.00013746C27.0908 -0.0072257 27.8181 0.281479 28.3618 0.804188C28.9056 1.3269 29.2223 2.04187 29.2439 2.7954C29.2655 3.54893 28.9903 4.28085 28.4774 4.8338C27.9646 5.38676 27.255 5.7166 26.5012 5.75241H26.3362C25.5591 5.75904 24.8109 5.45781 24.2556 4.91464C23.7002 4.37147 23.3829 3.63062 23.373 2.85426V2.83506ZM28.7039 29.4863H23.8816V8.02623H28.6926L28.7039 29.4863Z"
            fill="currentColor"
          />
          <path
            d="M70.796 18.6693C70.796 25.5381 66.4133 29.9999 60.0598 29.9999C53.7062 29.9999 49.3574 25.5381 49.3574 18.6693C49.3574 11.8004 53.696 7.50342 60.0598 7.50342C66.4235 7.50342 70.796 11.7936 70.796 18.6693ZM65.8144 18.6693C65.8144 14.6308 64.0514 11.2878 60.27 11.2878H59.8846C56.0614 11.2878 54.3842 14.7663 54.3842 18.6693C54.3842 22.6208 56.0614 26.2246 59.8846 26.2246H60.27C64.0943 26.2246 65.8144 22.7913 65.8144 18.6693Z"
            fill="currentColor"
          />
          <path
            d="M92.1465 4.6684H95.8872V0.922363H90.5688C87.1072 0.922363 85.5397 2.80329 85.5397 6.09207V7.99219H77.9679V6.46465C77.9673 5.98905 78.1557 5.53267 78.4919 5.19584C78.828 4.85901 79.2842 4.6693 79.7603 4.6684H83.5089V0.922363H78.1883C74.7255 0.922363 73.1592 2.80329 73.1592 6.09207V29.4771H77.9679V11.7518H85.5397V29.4771H90.3485V11.7518H95.8861V8.00348H90.3485V6.46465C90.3482 5.98914 90.5367 5.53294 90.8728 5.19617C91.2088 4.85941 91.6649 4.6696 92.1408 4.6684"
            fill="currentColor"
          />
          <path
            d="M4.85645 14.528L15.6085 29.4771H21.7101L10.6619 14.528L21.1665 0.922363H15.0378L4.85645 14.528Z"
            fill="currentColor"
          />
          <path
            d="M36.7129 18.2515L44.7956 29.4885H50.4869L42.168 18.2538L50.1422 8.02051H44.3706L36.7129 18.2515Z"
            fill="currentColor"
          />
        </svg>
      )}
      {children}
    </div>
  );
}

export default Logo;
